import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import HeroImage from "./images/background-boat.png";
import { mediaQueries, color } from "./components/Variables";
import TeamMember from "./components/TeamMember";
import RockAllLogo from "./images/logo/rockall-logo.svg";
import ContactUs from "./components/ContactUs";
import NavigationBar from "./components/NavigationBar";
import SupportingCast from "./components/SupportingCast";
import Footer from "./components/Footer";
import Ciaran from "./images/ciaran_charles.jpg";
import Jessica from "./images/jessica_sery.jpg";
import Partners from './components/Partners';
import Projects from './components/Projects';


function App() {

    const [isOpen, setOpen] = React.useState(false);
    return (
        <div className="App">
            <NavigationBar />
            <FullContainer fluid>
                <LogoContainer>
                    <Logo src={RockAllLogo} />
                </LogoContainer>
            </FullContainer>

            <AboutContainer id="about-us">
                <Row>
                    <Col>
                        <About>
                            Is comhlacht léiriúcháin Gaeltachta é <strong>Rockall</strong> a chruthaíonn scéalta le samhlaíocht fhisiúil.
                        </About>

                        <About>
                            <strong>Rockall Films</strong> is a media company that makes Discovery of Voice content for worldwide engagement across all platforms. Discovery of voice describes stories about who we are and who we want to become.</About>

                        <ReadMoreContainer className={`${isOpen ? "open" : ""}`}>
                            <About>Representing audiences that are underrepresented, our purpose is telling stories; funny stories, serious stories but always deeply human stories, and always with heart.</About>

                            <About>If thats what you're looking for, you've come to the right place. <i>Tarraing suas do chathaoir</i>.</About>
                        </ReadMoreContainer>
                        <ReadMore onClick={() => setOpen(!isOpen)}>{isOpen ? "READ LESS..." : "READ MORE..."}</ReadMore>
                    </Col>
                </Row>
            </AboutContainer>

            <TeamContainer id="the-team">
                <Row>

                    <TeamMember
                        member="ciaran"
                        image={Ciaran}
                        title="Ciarán Charles"
                        subTitle="Producer & Founder"
                        description1="Ciarán is responsible for developing and executing the company’s creative and business strategies, as well as developing relationships with key talent and originating TV and Film productions."
                        description2={
                            <>He previously worked at the <a target="_blank" rel="noopener noreferrer" href="https://www.galwayfilmfleadh.com/">Galway Film Fleadh</a> as their Marketplace Co-Ordinator. He is a graduate of <a target="_blank" rel="noopener noreferrer" href="https://eave.org/">EAVE Producers programme</a> (2020) <a target="_blank" rel="noopener noreferrer" href="https://lim-lessismore.eu/">LIM - Less is More</a> (2020) and Puttnam Scholar. <br />Prior to founding Rockall Films, Ciarán worked in script development and production across
                                the film and television industry. Ciarán is a member of the Irish Film and Television Academy and the European Film Academy.
                            </>}

                    />

                    <TeamMember
                        member="jessica"
                        image={Jessica}
                        title="Jessica Sery"
                        subTitle="Producer"
                        description1="Jessica Sery is a creative and innovative producer from Paris.
        Growing up in the world of animation at Toon Factory studios in
        France, she picked up a knack for artistic storytelling and a fierce
        passion for scripts with a cultural twist and a subtle moral impact."
                        description2="Upon graduating from film schools in her hometown and New York she worked as a freelancer across short films, series, podcasts, and commercials before joining Rockall Films."
                    />

                </Row>
            </TeamContainer>

            <SupportingCast />

            <ProjectsContainer id="projects">
                <Projects />
            </ProjectsContainer>

            <Partners />

            <div id="contact-us">
                <ContactUs />
            </div>


            <Footer />

        </div>
    );
}


export default App;

const Paragaph = styled.p`
    margin-top: 16px;
`;

const FullContainer = styled(Container)`
  height: 100vh;
  background: url(${HeroImage}) no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  background-position: bottom;
`;

const LogoContainer = styled.div`
  text-align: center;
  width: 100%;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 300px;
  position: relative;
  top: -70px;

  @media (min-width: ${mediaQueries.medium}) {
      max-width: 450px;
  }
`;

const AboutContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 40px;

  @media (min-width: ${mediaQueries.medium}) {
    height: 100vh;
    display: flex;
    align-items: center;
  }
`;

const About = styled.p`
  font-size: 30px;
  line-height: 50px;
  color: #222;
`;

const TeamContainer = styled(Container)`
    padding-top: 60px;
`

const ProjectsContainer = styled(Container)`
    padding-top: 60px;
    padding-bottom: 40px;
`

const ReadMore = styled.div`
    color: ${color.red};
    font-size: 14px;

    @media (min-width: ${mediaQueries.medium}) {
      display: none;
  }
`

const ReadMoreContainer = styled.div`
    display: none;

    @media (min-width: ${mediaQueries.medium}) {
        display: block;
    }

    &.open {
        display: block;
    }
`
import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import styled from "styled-components";

const NavigationBar = () => {
    return (
        <NavbarTransparent fixed="top" collapseOnSelect expand="md" variant="light">
            {/* <Navbar.Brand href="/"><LogoImage src={RockAllLogo} /></Navbar.Brand> */}
            <Navbar.Toggle
                aria-controls="responsive-navbar-nav"
                className="ml-auto"
            />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="#about-us">About</Nav.Link>
                    <Nav.Link eventKey={2} href="#the-team">
                        Team
                    </Nav.Link>
                    <Nav.Link eventKey={3} href="#projects">
                        Projects
                    </Nav.Link>
                    <Nav.Link eventKey={4} href="#contact-us">
                        Contact
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </NavbarTransparent>
    );
};

export default NavigationBar;

const NavbarTransparent = styled(Navbar)`
  background: rgba(255, 255, 255, 0.85);
`;


export const mediaQueries = {
  small: "480px",
  medium: "768px",
  large: "1024px",
};

export const color = {
  red: "#B42320",
  black: "#222",
  white: "#fff",
  cream: "#e8efe9",
};

import React from "react";
import styled from "styled-components";
import TG4 from "../images/partners/TG4-Logo.svg"
import FilmIreland from "../images/partners/film-ireland.svg"
import BBC from "../images/partners/bbc.svg"
import Lgo from "../images/partners/lgo.svg"

import { Container, Row, Col } from "react-bootstrap";

const Partners = () => {
    return (
        <PartnerContainer>
            <Row>
                <Col xs={12}><Title>INDUSTRY PARTNERS</Title></Col>
                <ImageContainer sm={3}>
                    <PartnerImage src={TG4} alt="TG4 logo" />
                </ImageContainer>
                <ImageContainer sm={3}>
                    <PartnerImage src={FilmIreland} alt="film Ireland logo" />
                </ImageContainer>
                <ImageContainer sm={3}>
                    <PartnerImage src={BBC} alt="BBC logo" />
                </ImageContainer>
                <ImageContainer sm={3}>
                    <PartnerImage src={Lgo} alt="Eave logo" />
                </ImageContainer>
            </Row>
        </PartnerContainer>
    );
};

export default Partners;

const PartnerContainer = styled(Container)`
    margin-top: 40px;
`

const ImageContainer = styled(Col)`
    display: flex;
    justify-content: center;
`

const PartnerImage = styled.img`
    width: 100%;
    margin-bottom: 16px;
    max-width: 250px;
    align-self: center;
`

const Title = styled.h3`
    text-align: center;

    
`
import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import WhitePuddingImage from "../images/projects/White_Pudding_Supper.jpg";
import TheClimb from "../images/projects/The_Climb.jpg";
import RbPoster from "../images/projects/RB.png";

const Projects = () => {
    return (
        <ProjectsContainer>
            <Row>
                <Col xs={12}><Title>IN DEVELOPMENT</Title></Col>

                <Col sm={3}>
                    <ProjectTitle>WHO IS JOSEPHINE</ProjectTitle>
                    <p>Feature Film<br />
                        In Development with Screen Ireland</p>
                </Col>

                <Col sm={3}>
                    <ProjectTitle>MILKGUM</ProjectTitle>
                    <p>Short Drama<br />
                        In Development with Short Circuit / BFI Network</p>
                </Col>
            </Row>

            <Row className="pt-4">
                <Col xs={12}><Title>IN POST</Title></Col>
                <ProjectContainer sm={12} image={WhitePuddingImage}>
                    <ProjectDescription className="d-none d-md-flex">
                        <ProjectTitle>WHITE PUDDING SUPPER</ProjectTitle>
                        <p>Short Drama<br />
                            BFI Network</p>
                    </ProjectDescription>
                </ProjectContainer>
                <ProjectDescription className="d-md-none">
                    <ProjectTitle>WHITE PUDDING SUPPER</ProjectTitle>
                    <p>Short Drama<br />
                        BFI Network</p>
                </ProjectDescription>
            </Row>

            <Row className="pt-4">
                <Col xs={12}><Title>COMPLETED</Title></Col>

                <ProjectContainer sm={12} image={TheClimb}>
                    <ProjectDescription className="d-none d-md-flex">
                        <ProjectTitle>L'ASCENSION</ProjectTitle>
                        <p>Feature Film
                            <br />
                            Netflix
                        </p>
                    </ProjectDescription>
                </ProjectContainer>
                <ProjectDescription className="d-md-none">
                    <ProjectTitle>L'ASCENSION</ProjectTitle>
                    <p>Feature Film
                        <br />
                        Netflix
                    </p>
                </ProjectDescription>

                <ProjectContainer sm={12} image={RbPoster} className="mt-4">
                    <ProjectDescription className="d-none d-md-flex">
                        <ProjectTitle>RONNACHAI BUI
                        </ProjectTitle>
                        <p>Short Drama
                            <br />
                            TG4
                        </p>
                    </ProjectDescription>
                </ProjectContainer>
                <ProjectDescription className="d-md-none">
                    <ProjectTitle>RONNACHAI BUI
                    </ProjectTitle>
                    <p>Short Drama
                        <br />
                        TG4
                    </p>
                </ProjectDescription>
            </Row>
        </ProjectsContainer>
    );
};

export default Projects;

const ProjectsContainer = styled(Container)`
    margin-top: 40px;
`

const Title = styled.h3`
    text-align: center;
`

const ProjectTitle = styled.h4`
    margin: 0;
`

const ProjectContainer = styled(Col)`
    background-image: url(${props => props.image});
    height: 240px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    padding: 0;
`

const ProjectDescription = styled.section`
    width: fit-content;
    background: black;
    padding: 16px 0 0 0;
    width: 100%;
    color: white;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media only screen and (min-width: 768px) {
        width: 200px;
    }
    
`
import React from "react";
import styled from "styled-components";

import { Container } from "react-bootstrap";

const SupportingCast = () => {
    return (
        <SupportingCastContainer>
            <h3>SUPPORTING CAST</h3>
            <CastList>
                <CastItem>Ellen Parsonage</CastItem>
                <CastItem>Oisin Power</CastItem>
                <CastItem>Dermot O’Hare</CastItem>
            </CastList>
        </SupportingCastContainer>
    );
};

export default SupportingCast;

const SupportingCastContainer = styled(Container)`
    text-align: center;
`

const CastList = styled.ul`
    list-style-type: none;
    padding: 0;
`

const CastItem = styled.li`
    font-size: 18px;
`
import React from "react";
import styled from "styled-components";
import { color } from "./Variables";
import { Container, Row, Col } from "react-bootstrap";
import Instagram from "../images/social/instagram.svg";

const ContactUs = () => {
    return (
        <ContactContainer>
            <Row>
                <Col>
                    <Email target="_blank" href="mailto:info@rockallfilms.com.com">
                        info@rockallfilms.ie</Email>
                    <SocialLink href="https://www.instagram.com/rockallfilms/" target="_blank" ><SocialImage src={Instagram} /></SocialLink>
                </Col>
            </Row>
        </ContactContainer >
    );
};

export default ContactUs;

const ContactContainer = styled(Container)`
  height: 50vh;
  display: flex;
  align-items: center;
  background-position: bottom;
  justify-content: center;
`;

const Email = styled.a`
  color: #566473;
  font-weight: 700;
  font-size: 32px;
  color: ${color.black};

  &:hover {
    text-decoration: none;
    color: #566473;
  }
`;

const SocialImage = styled.img`
    max-width: 50px;
`

const SocialLink = styled.a`
    display: block;
    text-align: center;
    margin-top: 16px;
`

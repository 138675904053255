import React from "react";
import styled from "styled-components";
import { color, mediaQueries } from "./Variables";
import { Col } from "react-bootstrap";

const TeamMember = (props) => {
    const [isOpen, setOpen] = React.useState(false);

    return (
        <TeamContainer xs={12} md={6}>
            <TeamImage src={props.image} />

            <Title>{props.title}</Title>
            <SubTitle>{props.subTitle}</SubTitle>
            <MemberContent>
                <Description>{props.description1}</Description>
                <Description className={`${isOpen ? "open" : ""}`}
                >
                    {props.description2}</Description>

                <ReadMore onClick={() => setOpen(!isOpen)}>{isOpen ? "LESS..." : "READ MORE..."}</ReadMore>
            </MemberContent>
        </TeamContainer>
    );
};

export default TeamMember;

const TeamContainer = styled(Col)`
    
`

const TeamImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 50%;
    margin: 0 auto 16px;
    max-width: 300px;
    display: flex;
`

const Title = styled.h1`
  color: ${color.black};
  text-align: center;
`;

const SubTitle = styled.h3`
  color: ${color.black};
  text-align: center;
`;

const Description = styled.p`
  color: ${color.black};
  font-size: 18px;
  line-height: 28px;

  &:last-of-type{
    display: none;

    @media (min-width: ${mediaQueries.medium}) {
        display: block;
    }
  }

    &.open {
        display: block;
    }

`;

const MemberContent = styled.div`
    padding: 16px 24px 24px 24px;
`

const ReadMore = styled.div`
    color: ${color.red};
    font-size: 14px;

    @media (min-width: ${mediaQueries.medium}) {
      display: none;
  }
`
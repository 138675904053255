import React from "react";
import styled from "styled-components";
import RockAllLogo from "../images/logo/Rockall-logo-horizontal-Black.svg";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
    return (
        <FooterContainer>
            <Row>
                <Col xs={12}>
                    <h3>SUBMISSIONS</h3>
                    <Submissions>
                        It is company policy that we only accept unsolicited script
                        submissions via recognised literary or talent agents, or from
                        industry professionals who have a proven track record, or with
                        whom we have a previous working relationship. If you would like to
                        make an unsolicited submission, send a logline and synopsis ONLY
                        to: info@rockallfilms.ie
                    </Submissions>
                </Col>
                <CopyRight xs={12}>
                    <Logo src={RockAllLogo} />
                </CopyRight>
            </Row>

        </FooterContainer>
    );
};

export default Footer;


const FooterContainer = styled(Container)`
  display: flex;
  align-items: flex-end;
`;

const Submissions = styled.p`
  font-size: 16px;
`;

const CopyRight = styled(Col)`
    padding-top: 16px;
    text-align: center;
`
const Logo = styled.img`
  width: 200px;
  height: auto;
  margin: 24px 0;
`;